import { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Html } from '@react-three/drei'
import * as THREE from 'three'
import { IconButton, Progress } from '@chakra-ui/react'
import { Search2Icon, CloseIcon } from '@chakra-ui/icons'
import { Suspense } from 'react'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { TextureLoader } from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

// Configure global DRACO loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/')
dracoLoader.preload()

// Configure loading manager
const loadingManager = new THREE.LoadingManager()

// Create a configured GLTFLoader instance
const createGLTFLoader = () => {
  const loader = new GLTFLoader(loadingManager)
  loader.setDRACOLoader(dracoLoader)
  return loader
}

function Model({ modelUrl, textureUrl, onMeshClick }) {
  // Use a ref to store the loader instances
  const loaderRef = useRef({
    gltf: null,
    texture: null
  })

  // Initialize loaders if they haven't been created
  if (!loaderRef.current.gltf) {
    loaderRef.current.gltf = createGLTFLoader()
    loaderRef.current.texture = new TextureLoader(loadingManager)
  }

  // Load model and texture using the configured loaders
  const gltf = useLoader(
    GLTFLoader, 
    modelUrl, 
    (loader) => {
      loader.setDRACOLoader(dracoLoader)
      loader.manager = loadingManager
      return loader
    }
  )
  
  const texture = useLoader(
    TextureLoader, 
    textureUrl, 
    (loader) => {
      loader.manager = loadingManager
      return loader
    }
  )
  const meshRef = useRef()

  // Optimize texture
  useEffect(() => {
    if (texture) {
      texture.flipY = false
      texture.generateMipmaps = true
      texture.minFilter = THREE.LinearMipMapLinearFilter
      texture.magFilter = THREE.LinearFilter
      texture.anisotropy = 16
    }
  }, [texture])

  const mesh = useMemo(() => {
    return Object.values(gltf.nodes).find(node => node.isMesh)
  }, [gltf])

  if (!mesh) return null

  return (
    <mesh
      ref={meshRef}
      geometry={mesh.geometry}
      onClick={onMeshClick}
    >
      <meshStandardMaterial map={texture} />
    </mesh>
  )
}
// Marker and Label components remain the same
const Marker = React.memo(({ position, radius = 0.1 }) => {
  const geometry = useMemo(() => {
    const points = [
      new THREE.Vector3(-radius, 0, 0),
      new THREE.Vector3(radius, 0, 0),
      new THREE.Vector3(0, -radius, 0),
      new THREE.Vector3(0, radius, 0)
    ]
    return new THREE.BufferGeometry().setFromPoints(points)
  }, [radius])

  return (
    <group position={position}>
      <line geometry={geometry}>
        <lineBasicMaterial color="#ff0000" depthTest={false} />
      </line>
    </group>
  )
})

const Label = React.memo(({ position, children }) => {
  return (
    <Html position={position} style={{ 
      background: 'rgba(0,0,0,0.65)',
      color: 'white',
      padding: '2px 4px',
      borderRadius: '2px',
      fontSize: '12px',
      userSelect: 'none',
      pointerEvents: 'none'
    }}>
      {children}
    </Html>
  )
})

const MeasurementLine = React.memo(({ points, showDistance }) => {
  const geometry = useMemo(() => {
    return new THREE.BufferGeometry().setFromPoints(points)
  }, [points])

  const midPoint = useMemo(() => {
    return new THREE.Vector3().addVectors(points[0], points[1]).multiplyScalar(0.5)
  }, [points])

  const distance = points[0].distanceTo(points[1])

  return (
    <>
      <line geometry={geometry}>
        <lineBasicMaterial color="#ff0000" depthTest={false} />
      </line>
      {showDistance && (
        <Label position={midPoint}>
          {distance.toFixed(2)}
        </Label>
      )}
    </>
  )
})

const SceneLighting = () => {
  return (
    <>
      {/* Main ambient light for overall illumination */}
      <ambientLight intensity={0.8} />

      {/* Main directional light (simulates sun) */}
      <directionalLight 
        position={[10, 10, 5]} 
        intensity={1} 
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />

      {/* Fill light from the opposite side */}
      <directionalLight 
        position={[-10, 10, -5]} 
        intensity={0.6} 
      />

      {/* Bottom fill light for shadows */}
      <directionalLight 
        position={[0, -10, 0]} 
        intensity={0.3} 
      />

      {/* Front fill light */}
      <directionalLight 
        position={[0, 0, 10]} 
        intensity={0.4} 
      />
    </>
  )
}


// Calculation functions remain the same
const calculateAngle = (p1, p2, p3) => {
  const v1 = new THREE.Vector3().subVectors(p1, p2)
  const v2 = new THREE.Vector3().subVectors(p3, p2)
  return v1.angleTo(v2) * (180 / Math.PI)
}

const calculateTriangleArea = (p1, p2, p3) => {
  const a = p1.distanceTo(p2)
  const b = p2.distanceTo(p3)
  const c = p3.distanceTo(p1)
  const s = (a + b + c) / 2
  return Math.sqrt(s * (s - a) * (s - b) * (s - c))
}

// Main viewer component
export default function ModelViewer({ reef, date, selected_texture }) {
  const [measureMode, setMeasureMode] = useState(false)
  const [measurePoints, setMeasurePoints] = useState([])
  const [area, setArea] = useState(null)

  // Build URLs based on the props
  const modelUrl = `/models/${reef}/${date}/model.glb`
  const textureUrl = `/models/${reef}/${date}/${selected_texture}/texture.jpg`

  // Add loading state
  const [loadingProgress, setLoadingProgress] = useState(0)

  // Update the cleanup effect to include progress tracking
  useEffect(() => {
    const manager = loadingManager
    
    manager.onProgress = (url, loaded, total) => {
      const progress = (loaded / total) * 100
      setLoadingProgress(progress)
      console.log(`Loading: ${url} (${progress.toFixed(2)}%)`)
    }
    
    manager.onError = (url) => {
      console.error('Error loading:', url)
      setLoadingProgress(0)
    }
    
    manager.onLoad = () => {
      console.log('Loading complete')
      setLoadingProgress(100)
    }

    return () => {
      manager.onProgress = () => {}
      manager.onError = () => {}
      manager.onLoad = () => {}
      setLoadingProgress(0)
    }
  }, [reef, date, selected_texture])

  const handleMeshClick = useCallback((event) => {
    if (!measureMode) return
    event.stopPropagation()
    const point = event.point.clone()
    
    setMeasurePoints(prev => {
      if (prev.length >= 3) return [point]
      const newPoints = [...prev, point]
      
      if (newPoints.length === 3) {
        const area = calculateTriangleArea(newPoints[0], newPoints[1], newPoints[2])
        setArea(area)
        setMeasureMode(false)
      }
      
      return newPoints
    })
  }, [measureMode])

  const handleClearMeasurement = () => {
    setMeasurePoints([])
    setArea(null)
    setMeasureMode(false)
  }

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0,0,0,0.65)',
        color: 'white',
        padding: '4px',
        borderRadius: '4px',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        {area ? (
          <>
            <span>Area: {area.toFixed(2)} m<sup>2</sup></span>
            <IconButton
              aria-label="Clear measurement"
              icon={<CloseIcon />}
              color="white"
              size="sm"
              onClick={handleClearMeasurement}
              variant="ghost"
              colorScheme="whiteAlpha"
            />
          </>
        ) : (
          <>
            {measureMode ? 
              <span>
                {measurePoints.length === 0 && "Click first point"}
                {measurePoints.length === 1 && "Click second point"}
                {measurePoints.length === 2 && "Click third point to complete triangle"}
              </span> : 
              <IconButton
                aria-label="Measure"
                icon={<Search2Icon />}
                color="white"
                size="sm"
                onClick={() => setMeasureMode(!measureMode)}
                variant="ghost"
                colorScheme="whiteAlpha"
                isActive={measureMode}
              />
            }
          </>
        )}
      </div>
      
      <Canvas
        camera={{ 
          position: [0, 2, 10],
          up: [0, 0, 1],
          fov: 45 
        }}
        shadows
        style={{ background: '#000' }}
        gl={{
          antialias: true,
          toneMapping: THREE.ACESFilmicToneMapping,
          toneMappingExposure: 1.2,
          outputColorSpace: 'srgb'
        }}
        performance={{ min: 0.5 }} // Add performance limiter
      >
        <SceneLighting />
        <Suspense fallback={
          <Html center>
            <div style={{ width: '200px', textAlign: 'center' }}>
              <Progress 
                value={loadingProgress} 
                size='sm' 
                colorScheme='blue' 
                borderRadius='md'
                mb={2}
              />
              <div style={{ color: 'white', fontSize: '14px' }}>
                Loading: {loadingProgress.toFixed(1)}%
              </div>
            </div>
          </Html>
        }>
          <Model 
            modelUrl={modelUrl}
            textureUrl={textureUrl}
            onMeshClick={measureMode ? handleMeshClick : undefined}
          />
        </Suspense>
        
        {measurePoints.map((point, index) => (
          <Marker key={index} position={point} />
        ))}
        
        {measurePoints.length >= 2 && (
          <MeasurementLine 
            points={[measurePoints[0], measurePoints[1]]} 
            showDistance={true}
          />
        )}
        {measurePoints.length === 3 && (
          <>
            <MeasurementLine 
              points={[measurePoints[1], measurePoints[2]]} 
              showDistance={true}
            />
            <MeasurementLine 
              points={[measurePoints[2], measurePoints[0]]} 
              showDistance={true}
            />
            <Label position={measurePoints[0]}>
              {calculateAngle(measurePoints[1], measurePoints[0], measurePoints[2]).toFixed(1)}°
            </Label>
            <Label position={measurePoints[1]}>
              {calculateAngle(measurePoints[0], measurePoints[1], measurePoints[2]).toFixed(1)}°
            </Label>
            <Label position={measurePoints[2]}>
              {calculateAngle(measurePoints[0], measurePoints[2], measurePoints[1]).toFixed(1)}°
            </Label>
          </>
        )}
        
        <OrbitControls 
          enableDamping 
          dampingFactor={0.25}
          minDistance={1}
          maxDistance={75}
          rotateSpeed={0.25}
          zoomSpeed={0.2}
          enableZoom={true}
          enablePan={true}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
      </Canvas>
    </div>
  )
}