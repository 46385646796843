import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { 
  Flex,
  Box,
  Select,
  Card,
  CardBody,
  Heading,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  Spinner,
  Center,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/react'
import theme from "./theme/theme.js";
import { useState, useEffect } from 'react';
import ModelViewer from './components/ModelViewer';

const texture_label_map = {
  'metashape': 'Color',
  'reconstruction': 'Color',
  'coral_mesh': 'Coral',
  'substrate_mesh': 'Substrate',
  'anomalies_mesh': 'Anomalies',
  'rugosity_mesh': 'Rugosity',
}


function App() {
  const [modelList, setModelList] = useState(undefined);
  const [reef, setReef] = useState(undefined);
  const [texture, setTexture] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDataReady = reef && date && texture && modelList && modelList[reef] && modelList[reef][date] && modelList[reef][date][texture];

  useEffect(() => {
    fetch('/models/list')
      .then(res => res.json())
      .then(data => {
        delete data['manifest.json'];
        setModelList(data);
        const firstReef = data['Tektite'] ? 'Tektite' : Object.keys(data)[0];
        setReef(firstReef);
      });
  }, []);

  useEffect(() => {
    if (reef && modelList && modelList[reef]) {
      const availableDates = Object.keys(modelList[reef]);
      availableDates.sort().reverse();
      setDate(availableDates[0]);
    }
  }, [reef, modelList]);

  useEffect(() => {
    if (reef && date && modelList && modelList[reef] && modelList[reef][date]) {
      const availableTextures = Object.keys(modelList[reef][date])
        .filter(key => texture_label_map[key])
        .sort((a, b) => texture_label_map[a].localeCompare(texture_label_map[b]));
      let defaultTexture = 'reconstruction';
      if (!availableTextures[defaultTexture]) defaultTexture = 'metashape';
      if (!availableTextures[defaultTexture]) defaultTexture = availableTextures[0];
      setTexture(defaultTexture);
    }
  }, [date, reef, modelList]);

  const ControlForm = () => {
    if (!modelList || !isDataReady) return null;
    
    const availableDates = reef ? Object.keys(modelList[reef]) : [];
    const availableTextures = (reef && date) 
      ? Object.keys(modelList[reef][date])
        .filter(key => texture_label_map[key])
      : [];

    return (
      <Stack spacing={4}>
        <Box>
          <Select value={reef} onChange={e => {
            const newReef = e.target.value;
            setReef(newReef);
          }}>
            {Object.keys(modelList).map(site => (
              <option key={site} value={site}>{site}</option>
            ))}
          </Select>
        </Box>
        <Box>
          <Select value={date} onChange={e => {
            const newDate = e.target.value;
            setDate(newDate);
          }}>
            {availableDates.map(date => (
              <option key={date} value={date}>{date.replace(/_/g, '-')}</option>
            ))}
          </Select>
        </Box>
        <Box>
          <Tabs 
            orientation='vertical' 
            width="100%"
            variant='line'
            onChange={(index) => setTexture(availableTextures[index])}
            index={availableTextures.indexOf(texture)}
          >
            <TabList width="100%">
              {availableTextures.map(texture => (
                <Tab key={texture}>{texture_label_map[texture]}</Tab>
              ))}
            </TabList>
          </Tabs>
        </Box>
        <Box textAlign="center" fontSize="2xs" mt={2}>
          By <a href="https://warp.whoi.edu/" target="_blank" rel="noopener noreferrer" style={{color: 'rgb(99, 179, 237)'}}>WARPLab</a>
        </Box>
      </Stack>
    );
  }


  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <Flex 
          className="mobile-header"
          display={{base: "flex", md: "none"}}
          backgroundColor="gray.800"
          justifyContent="left"
          alignItems="center"
          gap="4"
        >
          <button onClick={() => setDrawerOpen(true)}>
              <HamburgerIcon />
          </button>
          <Image src='img/tektite.png' alt='Tektite' className='tektite-logo-sm' />
          <Heading size="md">TEKTITE AI</Heading>
        </Flex>
        <Box className="mobile-controls" display={{sm: "block", md: "none"}}>
          <Drawer
            isOpen={drawerOpen}
            placement='left'
            onClose={() => setDrawerOpen(false)}
          >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>TEKTITE AI</DrawerHeader>
            <DrawerBody>
              <ControlForm />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        </Box>
        <Box className='floating-boxes' display={{base: "none", md: "block"}}>
          <Flex gap="5" alignItems="stretch" justifyContent="center" alignContent="center" flexDirection="column">
            <Card className="controls">
              <CardBody>
                <Stack>
                  <Flex direction="column" alignItems="center">
                        <Image src='img/tektite.png' alt='Tektite' className='tektite-logo' />
                        <Heading size="lg">TEKTITE AI</Heading>
                  </Flex>
                  <ControlForm />
                </Stack>
              </CardBody>
            </Card>
          </Flex>
        </Box>
        <div className="canvas">
          {!isDataReady ? (
            <Center h="100%">
              <Spinner 
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
          ) : (
            <ModelViewer 
              reef={reef} 
              date={date} 
              selected_texture={texture} 
            />
          )}
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
